<script lang="ts">
    import OptionCheckbox from "./OptionCheckbox.svelte";
    import {_} from 'svelte-i18n';
    import {fade} from 'svelte/transition';
    import type RenderData from "../RenderData";
    import type EventEmitter from "../EventEmitter";
    import {Events} from "../EventEmitter";


    export let data: RenderData;
    export let eventEmitter: EventEmitter;

    let openedSettings = false;
    let openedCookieBar = false;

    eventEmitter.on(Events.OpenBar, () => {
        openedCookieBar = true;
    });

    eventEmitter.on(Events.CloseBar, () => {
        openedCookieBar = false;
    });

    eventEmitter.on(Events.OpenSettings, () => {
        openedSettings = true;
        document.body.style.overflow = 'hidden';
    });

    eventEmitter.on(Events.CloseSettings, () => {
        openedSettings = false;

    });

    function openSettings() {
        openedSettings = true;
        document.body.style.overflow = 'hidden';
    }

    function closeSettings() {
        openedSettings = false;
        document.body.style.removeProperty('overflow');
    }


    function confirmAll() {
        eventEmitter.emit(Events.ConfirmAll);
        openedCookieBar = false;
        document.body.style.removeProperty('overflow');
    }

    function confirmSelected(event: SubmitEvent) {
        const formData = new FormData(event.target as HTMLFormElement);
        const confirmed: string[] = [];
        formData.forEach((value, key) => {
            if (value !== 'on') {
                return;
            }
            confirmed.push(key)
        });
        eventEmitter.emit(Events.ConfirmSelected, confirmed);
        openedCookieBar = false;
        document.body.style.removeProperty('overflow');
    }

    function rejectAll() {
        eventEmitter.emit(Events.RejectAll);
        openedCookieBar = false;
        document.body.style.removeProperty('overflow');
    }
</script>

<div>
    {#if openedCookieBar}
        <div class="cookieBar {data.barDisplayMode === 'horizontal' ? 'cookieBar--horizontal' : '' }" transition:fade={{duration: 1000}}>

            <div class="cookieBar__content">
                <h4 class="cookieBar__title">{@html $_('bar_mainHeader')}</h4>
                <p class="cookieBar__text">{@html $_('bar_description')}</p>
            </div>

            <div class="cookieBtnWrap">
                {#if data.displaySettingsButton}
                <button class="cookieBtn cookieBtn--secondary"
                        on:click|preventDefault={openSettings}>{$_('button_settings')}</button>
                {/if}
                {#if data.displayRejectAllButton}
                <button class="cookieBtn cookieBtn--secondary"
                        on:click|once|preventDefault={rejectAll}>{$_('button_rejectAll')}</button>
                {/if}
                <button class="cookieBtn cookieBtn--primary"
                        on:click|once|preventDefault={confirmAll}>{$_('button_continue')}</button>
            </div>
        </div>
    {/if}
</div>
<div class="cookiePopup {openedSettings? 'is-active' : ''}">
    <div class="cookieInner">
        <span class="cookiePopup__close" on:click|preventDefault={closeSettings}></span>
        {#if openedSettings}
            <div class="">
                <form on:submit|preventDefault={confirmSelected}>
                    <h5 class="cookieInner__title">{$_('bar_settingsHeader')}</h5>

                    {#each data.options as option}
                        <OptionCheckbox bind:option={option}/>
                    {/each}
                    <section class="cookiePopup__btns">
                        <button class="cookieBtn cookieBtn--primary cookieBtn--right"
                                on:click|once|preventDefault={confirmAll}>{$_('button_confirmAll')}</button>
                        <button class="cookieBtn cookieBtn--secondary cookieBtn--right"
                                type="submit">{$_('button_confirmSelected')}</button>
                        <button class="cookieBtn cookieBtn--secondary cookieBtn--light cookieBtn--right"
                                on:click|once|preventDefault={rejectAll}>{$_('button_rejectAll')}</button>
                    </section>
                </form>
            </div>
        {/if}
    </div>
</div>


<style>
    :root {
        /* change in default config too */
        --cookieColor--text: #141414;
        --cookieColor--bg: #fff;
        --cookieColor--gray: #D6D6D6;
        --cookieColor--toggle: #141414;

        --cookieColor--primary: #1032CF;
        --cookieColor--primaryText: #fff;
        --cookieColor--primaryBorder: transparent;

        --cookieColor--secondary: #fff;
        --cookieColor--secondaryText: #141414;
        --cookieColor--secondaryBorder: #D6D6D6;

        --cookieColor--overlayBg: rgba(0, 0, 0, 0.85);
    }

    .cookieBar *,
    .cookieBar {
        box-sizing: border-box;
    }

    .cookieBar {
        max-width: 30%;
        position: fixed;
        bottom: 20px;
        left: 20px;
        background-color: #fff;
        color: #141414;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #D6D6D6;
        font-family: sans-serif;
        z-index: 9999;
    }

    .cookieBar--horizontal {
        max-width: 100%;
        right: 20px;
    }

    @media (max-width: 800px) {
        .cookieBar {
            width: min(460px, calc(100vw - 40px));
            max-width: unset;
        }

        .cookieBar--horizontal {
            max-width: 100%;
        }
    }



    .cookieBar__text {
        line-height: 1.65;
        font-size: 16px;
    }

    .cookieBtnWrap {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        flex-direction: row;
    }

    @media (max-width: 500px) {
        .cookieBtnWrap {
            flex-direction: column-reverse;
        }
    }

    .cookieBtn {
        display: inline-block;
        padding: 8px 16px;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        margin: 6px;
        border: 1px solid transparent;
    }

    @media (max-width: 500px) {
        .cookieBtn {
            width: 100%;
        }
    }

    .cookieBtn:hover,
    .cookieBtn:focus {
        outline: none;
    }

    .cookieBtn--primary {
        background-color: var(--cookieColor--primary);
        color: var(--cookieColor--primaryText);
        transition: opacity 0.25s;
        border-color: var(--cookieColor--primaryBorder);
    }

    .cookieBtn--primary:hover {
        opacity: 0.8;
    }

    .cookieBtn--secondary {
        background-color: var(--cookieColor--secondary);
        color: var(--cookieColor--secondaryText);
        border-color: var(--cookieColor--gray);
    }

    .cookieBtn--light {
        opacity: 0.7;
    }

    .cookieBtn--right {
        float: right;
    }

    .cookiePopup {
        background: var(--cookieColor--overlayBg);
        position: fixed;
        bottom: 0;
        z-index: 9999;
        width: 100%;
        display: flex;
        left: 0;
        visibility: hidden;
        opacity: 0;
        height: 100%;
        right: 100%;
    }

    @media (max-width: 760px) {
        .cookiePopup {
            overflow-y: scroll;
        }
    }

    .cookiePopup.is-active {
        right: 0;
        visibility: visible;
        opacity: 1;
    }

    .cookieInner {
        position: relative;
        margin: auto;
        max-width: 1400px;
        padding: 30px;
        background-color: #f8fafb;
        border-radius: 8px;
        overflow-y: auto;
        font-family: sans-serif;
        font-size: 14px;
        padding: 30px;
    }

    .cookiePopup__btns {
        flex-direction: row;
        text-align: right;
        margin-top: 30px;
        margin-right: -6px;
    }
    @media (max-width: 500px) {
        .cookiePopup__btns {
            flex-direction: column-reverse;
            margin-top: 15px;
        }

        .cookieInner {
            padding-bottom: 15px;
        }
    }

    .cookieInner__title,
    .cookieBar__title {
        font-size: 16px;
        text-transform: uppercase;
        margin-top: 0;
        font-weight: 700;
        margin-bottom: 30px;
    }

    .cookieBar__title {
        margin-bottom: 0;
    }

    .cookiePopup__close {
        position: absolute;
        right: 32px;
        top: 18px;
        width: 24px;
        height: 24px;
        opacity: 0.6;
        cursor: pointer;
    }
    .cookiePopup__close:hover {
        opacity: 1;
    }
    .cookiePopup__close:before, .cookiePopup__close:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 28px;
        width: 3px;
        background-color: #333;
    }
    .cookiePopup__close:before {
        transform: rotate(45deg);
    }
    .cookiePopup__close:after {
        transform: rotate(-45deg);
    }


</style>
