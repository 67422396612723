export default {
    button_continue: 'Dobře, pokračuj',
    button_confirmAll: 'Povolit vše',
    button_rejectAll: 'Odmítnout vše',
    button_confirmSelected: 'Potvrdit moje volby',
    button_settings: 'Nastavení',
    bar_mainHeader: 'Tato webová stránka používá cookies',
    bar_description: '<strong>Ke statistice a analýze</strong>naší návštěvnosti využíváme soubory cookie. Informace o tom, jak náš web používáte, sdílíme se svými partnery pro sociální média a analýzy. Partneři tyto údaje mohou zkombinovat s dalšími informacemi, které jste jim poskytli nebo které získali v důsledku toho, že používáte jejich služby.',
    bar_settingsHeader: 'Správa nastavení cookies',
    
}
