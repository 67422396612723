<script lang="ts">
    import type CookieBarOption from "../CookieBarOption";
    export let option: CookieBarOption;
</script>

<div class="cookiePopup__item">
    {#if option.decoration}
        <div class="cookiePopup__decoration">
            {@html option.decoration}
        </div>
    {/if}
    <div class="cookiePopup__itemText">
        <div><strong>{option.title}</strong> {#if option.subTitle}<small>{option.subTitle}</small>{/if}</div>
        <span>{option.description}</span>
    </div>
    <label class="cookiePopup__label">
        <input
            class="cookiePopup__input"
            type="checkbox"
            name={option.code}
            checked={option.enable}
            disabled={option.onlyRead}
        />
    </label>
</div>

<style>
    .cookiePopup__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--cookieColor--bg);
        padding: 16px;
        border: 1px solid #dedede;
        margin-top: -1px;
    }

    .cookiePopup__item:first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .cookiePopup__item:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .cookiePopup__label {
        display: block;
        height: 30px;
        width: 60px;
    }

    .cookiePopup__itemText {
        padding-right: 1.5em;
        flex-grow: 1;
    }

    .cookiePopup__input {
        font-size: 30px;
        appearance: none;
        width: 50px;
        height: 24px;
        background: #ddd;
        border-radius: 3em;
        position: relative;
        cursor: pointer;
        outline: 0;
        transition: all 0.2s ease-in-out;
    }

    .cookiePopup__input:disabled {
        opacity: 0.5;
    }

    .cookiePopup__input::before {
        position: absolute;
        content: "";
        width: 0.6em;
        height: 0.6em;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 0.25em rgb(0 0 0 / 30%);
        transform: scale(0.7);
        left: 0;
        top: 3px;
        transition: all 0.2s ease-in-out;
    }

    .cookiePopup__input:checked {
        background-color: var(--cookieColor--toggle);
    }

    .cookiePopup__input:checked:before {
        left: calc(100% - 0.6em);
    }
</style>