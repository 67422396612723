import { CookieState } from "./CookieState";
import { Events } from "./EventEmitter";
export var CookieBarOptionEvent;
(function (CookieBarOptionEvent) {
    CookieBarOptionEvent["Load"] = "load";
    CookieBarOptionEvent["Update"] = "update";
})(CookieBarOptionEvent || (CookieBarOptionEvent = {}));
class CookiesBarOptionEventHandler {
    constructor(event, callback) {
        this.event = event;
        this.callback = callback;
    }
}
export default class CookieBarOption {
    constructor(cookieStore, eventEmitter, code, languages = {}, onlyRead = false, defaultEnable = true, onLoad = null, onUpdate = null) {
        this._localeTag = 'cs';
        this._languages = {
            cs: {
                title: 'Title',
                subTitle: '',
                description: 'Description',
                decoration: ''
            }
        };
        this._handlers = [];
        this._cookieStore = cookieStore;
        this._eventEmitter = eventEmitter;
        this._code = code;
        this._languages = languages;
        this._onlyRead = onlyRead;
        this._defaultEnable = defaultEnable;
        if (typeof onLoad === "function") {
            this._handlers.push(new CookiesBarOptionEventHandler(CookieBarOptionEvent.Load, onLoad));
        }
        if (typeof onUpdate === "function") {
            this._handlers.push(new CookiesBarOptionEventHandler(CookieBarOptionEvent.Update, onUpdate));
        }
        const _this = this;
        this._eventEmitter.on(Events.LoadedOption, function (code) {
            if (code !== _this.code) {
                return;
            }
            _this._emitCookieBarEvent(CookieBarOptionEvent.Load);
        });
        this._eventEmitter.on(Events.UpdatedOption, function (code) {
            if (code !== _this.code) {
                return;
            }
            _this._emitCookieBarEvent(CookieBarOptionEvent.Update);
        });
    }
    get code() {
        return this._code;
    }
    get title() {
        return this.getLanguage(this._localeTag)['title'];
    }
    get subTitle() {
        return this.getLanguage(this._localeTag)['subTitle'];
    }
    get description() {
        return this.getLanguage(this._localeTag)['description'];
    }
    get decoration() {
        return this.getLanguage(this._localeTag)['decoration'];
    }
    get onlyRead() {
        return this._onlyRead;
    }
    get enable() {
        return (this.state === CookieState.Undefined && this._defaultEnable) || this.state === CookieState.Confirmed;
    }
    get state() {
        return this._cookieStore.getOptionState(this._code);
    }
    on(event, callback) {
        this._handlers.push(new CookiesBarOptionEventHandler(event, callback));
        return this;
    }
    off(event, callback) {
        this._handlers = this._handlers.filter((handler) => {
            return !(handler.event === event && handler.callback === callback);
        });
        return this;
    }
    _emitCookieBarEvent(event) {
        const _this = this;
        this._handlers
            .filter(handler => handler.event === event)
            .forEach(handler => { handler.callback(_this); });
    }
    setLocaleTag(localeTag) {
        this._localeTag = localeTag;
    }
    addLanguage(localeTag, language) {
        this._languages[localeTag] = language;
        return this;
    }
    getLanguage(localetag) {
        return this._languages[localetag] === undefined ? this._languages['cs'] : this._languages[localetag];
    }
}
